export const entityId = "2";
export const processType = "ENR";
export const channel = "WEB_CLIENT";
export const waitTime = 2000
export const redirectionUrlHomeBank = "https://www.bancolombia.com/";
export const proccessIdMock = "123"
export const sessionTokenMock = "9999"
export const acceptTyCExternal = true
export const soyYoTermsAndConditionsPolicies = "https://www.soyyo.co/politica-tratamiento-informacion/"
export const basicIdentityLevel = 1
export const cancelOperation = 0 
export const rejectTC = 1
export const rejectTCSY = 2
export const contentType = 'application/json';
export const docTypes = {
    ['TIPDOC_FS001']: 'CC',
    ['TIPDOC_FS002']: 'CE',
    ['TIPDOC_FS003']: 'NIT',
 };

export const minutesConverter =1000
const maxHex = 255
const titleCamera = 'Problemas con tu cámara';
const titleBrowser = 'Navegador no es compatible';
const titleDevice = 'Dispositivo no es compatible';
const titleSo = 'Tu sistema operativo no es compatible';
const titleWebModel = 'Navegador o dispositivo no compatible';
const titleErrorServer = 'Hubo un error en el sistema';
const titleErrorUnknown = 'Problemas en nuestro sistema';

const messageCamera = 'Intentémoslo nuevamente y recuerda aceptar el acceso a la cámara.';
const messageBrowser = 'Vuelve a ingresar después de actualizar tu navegador.';
const messageDevice = 'Inténtalo nuevamente desde otro dispositivo.';
const messageSo = 'Actualiza el sistema operativo a la última versión disponible y vuelve a ingresar para intentar nuevamente.';
const messageWebModel = 'Inténtalo nuevamente desde otro navegador.';
const messageErrorServer = 'Vuelve a ingresar e intenta nuevamente.';

const messageBrowserSecondary = (<>Te recomendamos utilizar: <br /><strong>Chrome, Opera, Microsoft Edge, Safari, Brave y Samsung Browser.</strong></>);
const messageDeviceSecondary = 'El actual no es compatible con el proceso de biometría facial.';
const messageWebModelSecondary = 'Si el problema persiste, vuelve a intentar desde otro dispositivo.';

export const sourceId = {
    basicDataRegister: "sdk_basic_data",
    captureFace: "sdk_capture_face",
    documentValidate: "sdk_document_validate",
    authenticate: "sdk_authenticate",
    authorization: "sdk_transaction",
    web: "front"
};

export const sdkEnrollmentFace = {
    ['EP001']: {code: 'BF010', codeLog: 'BF010-3'},
    ['EP002']: {code: 'BF006', codeLog: 'BF006-1'},
    ['EP003']: {code: 'BF006', codeLog: 'BF006-2'},
    ['EP004']: {code: 'BF010', codeLog: 'BF010-4'},
    ['EP005']: {code: 'BF006', codeLog: 'BF006-3'},
    ['EP006']: {code: 'BF006', codeLog: 'BF006-4'},
    ['EP007']: {code: 'BF006', codeLog: 'BF006-5'},
    ['EP015']: {code: 'BF005', codeLog: 'BF005-2'}
};

export const sdkEnrollmentDocument = {
    ['EP001']: {code: 'BF010', codeLog: 'BF010-5'},
    ['EP003']: {code: 'BF006', codeLog: 'BF006-6'},
    ['EP005']: {code: 'BF006', codeLog: 'BF006-8'},
    ['EP007']: {code: 'BF006', codeLog: 'BF006-7'},
    ['EP008']: {code: 'BF006', codeLog: 'BF006-9'}
};

export const sdkAuthenticate = {
    ['AP001']: {code: 'BF010', codeLog: 'BF010-6'},
    ['AP002']: {code: 'BF007', codeLog: 'BF007-1'},
    ['AP003']: {code: 'BF007', codeLog: 'BF007-2'},
    ['EP015']: {code: 'BF005', codeLog: 'BF005-2'}
};

export const sdkAuthorization = {
    ['TRX001']: {code: 'BF010', codeLog: 'BF010-7'},
    ['TRX002']: {code: 'BF008', codeLog: 'BF008-1'},
    ['TRX003']: {code: 'BF008', codeLog: 'BF008-2'}
};

export const sdkGeneric = {
    ['T007']: {code: 'BF020', codeLog: 'BF020-1'},
    ['T005']: {code: 'BF009', codeLog: 'BF009-1'},
    ['T006']: {code: 'BF019', codeLog: 'BF019-1'}
};

export const codesNoSDK = {
    ['successCode']: {code: 'BF000', codeLog: 'BF000-1'},
    ['unauthorized']: {code: 'BF002', codeLog: 'BF002-1'},
    ['enrollmentFailed']: {code: 'BF003', codeLog: 'BF003-1'},
    ['errorBlockedUser']: {code: 'BF005', codeLog: 'BF005-1'},
    ['successTAC']: {code: 'BF010', codeLog: 'BF010-1'},
    ['successTACSY']: {code: 'BF010', codeLog: 'BF010-2'},
    ['errorTAC']: {code: 'BF017', codeLog: 'BF017-2'},
    ['errorTACSY']: {code: 'BF017', codeLog: 'BF017-1'},
    ['operationCancel']: {code: 'BF011', codeLog: 'BF011-1'},
    ['unknownError']: {code: 'BF013', codeLog: 'BF013-1'},
    ['errorServiceConsume']: {code: 'BF014', codeLog: 'BF014-1'},
    ['errorGettingKey']: {code: 'BF015', codeLog: 'BF015-1'},
    ['errorGeneretingKey']: {code: 'BF015', codeLog: 'BF015-2'},
    ['errorDecryptingData']: {code: 'BF015', codeLog: 'BF015-3'},
    ['customerTransactionMismatch']: {code: 'BF016', codeLog: 'BF016-1'},
    ['successGettingKey']: {code: 'BF010', codeLog: 'BF010-8'},
    ['successGeneretingKey']: {code: 'BF010', codeLog: 'BF010-9'},
    ['successDecryptingData']: {code: 'BF010', codeLog: 'BF010-10'},
    ['failedRecatpcha']: {code: 'BF002-4', codeLog: 'BF002-4'},
    ['failedRecatpchaRetry']: {code: 'BF002-5', codeLog: 'BF002-5'},
    ['failedRecatpchaChallenge']: {code: 'BF002-6', codeLog: 'BF002-6'},
    ['redirectResponse']: {code: 'BF021', codeLog: 'BF021-1'},
};

const informationScreen =  {
    width: window.screen.width,
    height: window.screen.height,
    avail_height: window.screen.availHeight,
    avail_width: window.screen.availWidth,
    color_depth: window.screen.colorDepth,
    pixel_depth: window.screen.pixelDepth,
};
  
const informationNavigator = {
    user_agent: window.navigator.userAgent.slice(0, maxHex),
    vendor: window.navigator.vendor,
    plugins: window.navigator.plugins,
    plugins_length: window.navigator.plugins.length,
    platform: window.navigator.platform,
    cookie_enabled: window.navigator.cookieEnabled,
    app_name: window.navigator.appName,
    do_not_track: window.navigator.doNotTrack,
    language: window.navigator.language,
    language_length: window.navigator.languages.length,
    hardware_concurrency: window.navigator.hardwareConcurrency
};

export const device = {
    info:{
        screen: informationScreen, 
        navigator: informationNavigator, 
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
};

const incompatibilyModalTypes = {
    ['CAMERA']: {title: titleCamera, message: messageCamera, secondaryMessage: '', iconType: 'error', buttonBack: true},
    ['BROWSER']: {title: titleBrowser, message: messageBrowser, secondaryMessage: messageBrowserSecondary, iconType: 'stop' , buttonBack: false},
    ['DEVICE']: {title: titleDevice, message: messageDevice, secondaryMessage: messageDeviceSecondary, iconType: 'stop', buttonBack: false},
    ['SO']: {title: titleSo, message: messageSo, secondaryMessage:'', iconType: 'stop', buttonBack: false},
    ['WEB']: {title: titleWebModel, message: messageWebModel, secondaryMessage: messageWebModelSecondary, iconType: 'error', buttonBack: false},
    ['SERVER_ERROR']: {title: titleErrorServer, message: messageErrorServer, secondaryMessage:'', iconType: 'error', buttonBack: true},
    ['UNKNOWN']: {title: titleErrorUnknown, message: messageErrorServer, secondaryMessage:'', iconType: 'error', buttonBack: true}
}

export const sdkIncompatibilityErrors = {
    ['FEEDBACK_RETRY_INSECURE']: incompatibilyModalTypes.CAMERA,
    ['FEEDBACK_CAMERA_ACQUIRING_FAILED']: incompatibilyModalTypes.CAMERA,
    ['ERROR_BROWSER_NOT_SUPPORTED']: incompatibilyModalTypes.BROWSER,
    ['ERROR_BROWSER_VERSION_NOT_SUPORTED']: incompatibilyModalTypes.BROWSER,
    ['ERROR_BROWSER_FEATURE_NOT_SUPORTED']: incompatibilyModalTypes.BROWSER,
    ['ERROR_DEVICE_NOT_SUPORTED']: incompatibilyModalTypes.DEVICE,
    ['ERROR_DEVICE_NOT_SUPORTED_MEMORY']: incompatibilyModalTypes.DEVICE,
    ['ERROR_DEVICE_NOT_SUPORTED_ANDROID']: incompatibilyModalTypes.SO,
    ['ERROR_DEVICE_NOT_SUPORTED_IOS']: incompatibilyModalTypes.SO,
    ['ERROR_PLATFORM_NOT_SUPORTED_ANDROID']: incompatibilyModalTypes.SO,
    ['ERROR_PLATFORM_NOT_SUPORTED_IOS']: incompatibilyModalTypes.SO,
    ['ERROR_WEBRTC_NOT_SUPORTED']: incompatibilyModalTypes.WEB,
    ['ERROR_MODEL_FAIL']: incompatibilyModalTypes.SERVER_ERROR,
    ['ERROR_SERVER_INTERNAL']: incompatibilyModalTypes.SERVER_ERROR,
    ['ERROR_SERVER_CONNECTION_FAILURE']: incompatibilyModalTypes.SERVER_ERROR,
    ['DEFAULT']: incompatibilyModalTypes.UNKNOWN
};

export const errorCodes = {
    0: codesNoSDK.operationCancel, //Valor 0 para abandonos
    1: codesNoSDK.errorTAC, //Valor 1 para rechazo en T&C Banco
    2: codesNoSDK.errorTACSY, //Valor 2 para rechazo en T&C SoyYo
    default: codesNoSDK.operationCancel, //Valor Default para abandonos
};