import React from "react";
import { createPortal } from "react-dom";
import { 
  Overlay, 
  Wrapper, 
  Header, 
  CloseButton, 
  Container,
  ContainerButtons,
  ImageClose, 
  ImageError,
  ContainerDes, 
  TitleMargin, 
  DescriptionTiny, 
  Button 
} from "../Styles/ModalErrorStyle"

function modalError({isShowing, hide, buttonClose, redirectFunction1, redirectFunction2, title, message, buttonText, buttonTextBack, image, widthButton, buttonBack, secondaryMessage}) {
  const showing = isShowing;

  if (showing) {
    return (createPortal(
      <>
        <Overlay />
        <Wrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Container>
            <Header>
              {buttonClose && (
                <CloseButton type="button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                  <ImageClose />
                </CloseButton>
              )}
            </Header>
            <ContainerDes>
              <ImageError image={image}/>
              <TitleMargin>{title}</TitleMargin>
              {message && (<DescriptionTiny>{message}</DescriptionTiny>)}
              {secondaryMessage && (<DescriptionTiny>{secondaryMessage}</DescriptionTiny>)}
              <ContainerButtons>
                {buttonBack && (<Button onClick={redirectFunction2} width={widthButton} color="#FFFFFF" border="solid 1px black">{buttonTextBack}</Button>)}
                {buttonText && (<Button onClick={redirectFunction1} width={widthButton}>{buttonText}</Button>)}
              </ContainerButtons>
            </ContainerDes>
          </Container>
        </Wrapper>
      </>,
      document.body
    ));
  }
  return null;
}
export default modalError;
