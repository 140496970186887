import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import times from "../Images/Times.svg";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(19.028px);
`;

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  justify-content: center;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CloseButton = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #454648;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  align-content: center;
  z-index: 100;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1);
  width: ${props => props.width || "600px"};
  top: ${props => props.top || "0"};
  border-radius: 16px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: ${props => props.widthM};
    height: ${props => props.heightM};
  }

  @media screen and (min-width: 481px) and (max-width: 961px) {
    width: ${props => props.widthM};
  }
`;

const ImageClose = styled.div`
  background-image: url(${times});
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  padding: 5px;
`;

const modal = ({
  isShowing,
  hide,
  children,
  height,
  heightM,
  topM,
  top,
  buttonClose,
  width,
  widthM,
}) =>
  isShowing
    ? createPortal(
      <>
        <Overlay />
        <Wrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Container height={height} heightM={heightM} topM={topM} top={top} width={width} widthM={widthM}>
            <Header>
              {buttonClose && (
                <CloseButton type="button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                  <ImageClose />
                </CloseButton>
              )}
            </Header>
            {children}
          </Container>
        </Wrapper>
      </>,
      document.body
    )
    : null;

export default modal;
