import TermsAndConditionsSoyYo from "../Components/TermsAndConditionsSoyYo"
import TermsAndConditions from "../Components/TermsAndConditions"
import Context from "../Components/Context"
import Modal from "../Components/Modal";
import ChallengeScreen from "../Components/ChallengeScreen"

export function HandlerFlow({ machine, aceptTaC, aceptTaCSoyYo, begin, cancelOperation, isShowing, setRecatpcha}) {
    const isMatchModal = ["termsAndCoditionsSoyYo", "termsAndCoditionsBank", "contexFlow","challenge"];
    return ( 
        <>
            {(isMatchModal.some(machine.matches)) && (
                <Modal
                    isShowing={isShowing}
                    topM="0%"
                    top="0%"
                    buttonClose={false}
                    width="650px"
                    widthM="380px"
                >
                    {(machine.matches("termsAndCoditionsSoyYo")) && (<TermsAndConditionsSoyYo acept={aceptTaCSoyYo} cancel={cancelOperation}/>)}
                    {(machine.matches("termsAndCoditionsBank")) && (<TermsAndConditions acept={aceptTaC} cancel={cancelOperation}/>)}
                    {(machine.matches("contexFlow")) && (<Context acept={begin} cancel={cancelOperation}/>)}
                    {(machine.matches("challenge")) && (<ChallengeScreen onRecaptchaSubmit={setRecatpcha}/>)}
                </Modal>
            )}
        </>
    )
    
}